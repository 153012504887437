<template>
  <v-container class="container">
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">{{$t('profile.edit.title')}}</v-card-title>

      <div>
        <v-alert v-if="profile && profile.delete_initiated" tile type="info" class="mb-0">
          <div class="message-plus-button">
            <p>Your profile and all your data is scheduled to be deleted within 7 days from <strong>{{profile.delete_initiated | localDate }}</strong>.</p>
            <v-btn outlined :to="{name: 'profileKeep'}">{{ $t('profile.edit.keep-profile') }}</v-btn>
          </div>
        </v-alert>

        <v-form ref="form" v-model="valid" lazy-validation>

        <v-expansion-panels :value="0" class="mt-4" accordion>
          <!-- Account Panel -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
              {{$t('profile.edit.profile-title')}} 
              <v-spacer/> 
              <span></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="">
              <div v-if="!profile" class="align-center">
                <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mb-8"></v-progress-circular>
                <div>{{ $t('shared.loading')}}</div>
              </div>
              <div v-if="profile">
                <h3 class="subtitle">{{$t('profile.edit.profile-title')}}</h3>
                <v-text-field 
                    v-model="name" 
                    :rules="nameRules" 
                    :label="$t('profile.edit.displayname')" 
                    prepend-icon="fa-user"
                    required>
                </v-text-field>
                <v-text-field 
                  v-model="email" 
                  :rules="tenant.id == 'wmm' ? emailRulesRequired : emailRulesOptional" 
                  :label="tenant.id == 'wmm' ? $t('profile.edit.email-required') : $t('profile.edit.email')" 
                  prepend-icon="fa-envelope"
                  dense
                  >
                </v-text-field>
                <i18n path="profile.edit.privacy-message" tag="p" class="ml-8 text-muted">
                  <router-link to="/privacypolicy">{{$t('account.register.terms_privacy_privacy')}}</router-link>
                </i18n>
                <v-text-field
                  :value="profile.id"
                  :label="$t('profile.edit.profile-id')"
                  readonly
                  disabled
                  color="grey"
                  prepend-icon="fa fa-id-badge"
                  >
                  <template v-slot:append>
                    <v-btn
                      text
                      color="primary"
                      class="ma-0"
                      @click="copyProfileId">
                      {{$t('shared.copy')}}
                    </v-btn>
                  </template>
                </v-text-field>         

              <h3 class="subtitle">{{$t('profile.edit.avatar-title')}}</h3>
                <!-- {{$t('profile.edit.avatar-intro')}} -->
                <AvatarUploadForm :img="profile.imgUrl" v-on:change="udateAvatar" class="mb-4"></AvatarUploadForm>
                                        
                <p class="ml-4">
                  <v-btn v-if="profile.account.has_password && tenant.id !== 'wmm'" text color="primary" :to="{name: 'accountChangePassword'}"><v-icon small color="primary" class="mr-2">fa-key</v-icon> {{ $t('account.change-password.title') }}</v-btn>
                  <v-btn v-if="!profile.account.has_password" text color="primary" :to="{name: 'accountLink'}"><v-icon small color="primary" class="mr-2">fa-key</v-icon> {{ $t('account.set-email-password') }}</v-btn>
                  <v-btn text color="accent" :to="{name: 'profileTakeout'}"><v-icon small color="accent" class="me-2">fa-cloud-download</v-icon> {{ $t('profile.edit.request-export') }}</v-btn>
                  <v-btn text color="red" :to="{name: 'profileRemoval'}"><v-icon small color="red" class="me-2">fa-trash</v-icon> {{ $t('profile.edit.request-removal') }}</v-btn>
                </p>     
              </div>       
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Preferences -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-sliders-h</v-icon>
              {{$t('profile.edit.preferences-title')}} 
              <v-spacer/> 
              <span></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile">

              <h3 class="subtitle">{{$t('profile.edit.preferences-title')}}</h3>
              <p class="mb-0">{{$t('profile.edit.unit-field')}}</p>
              <v-radio-group v-model="profile.unit" row class="mt-0">
                <v-radio :label="$t('profile.edit.unit-metric')" value="METRIC"></v-radio>
                <v-radio :label="$t('profile.edit.unit-imperial')" value="IMPERIAL"></v-radio>
              </v-radio-group>

              <v-switch v-if="tenant.id !== 'cofi' || manual_connecting" v-model="manual_connecting" Zinset :false-value="true" :true-value="false" class="my-0" :label="$t('profile.edit.auto-post-label')" />
              <p v-if="tenant.id !== 'cofi' && manual_connecting" class="grey--text mt-n4">{{$t('profile.edit.auto-post-description')}}</p>

              <p class="mb-0">{{$t('profile.edit.time-zone-msg')}}</p>
              <v-text-field
                v-model="profile.time_zone"
                :label="$t('profile.edit.time-zone')"
                readonly
                color="grey"
                prepend-icon="fa fa-globe"
                :placeholder="$t('shared.not-set')"
                >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    class="ma-0"
                    @click="detectTimeZone">
                    {{$t('shared.detect')}}
                  </v-btn>
                </template>
              </v-text-field>
                    
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- About You -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-user</v-icon>
              {{$t('profile.edit.about-title')}} 
              <v-spacer/> 
              <span></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile">

              <h3 class="subtitle">{{$t('profile.edit.about-title')}}</h3>
              <p class="mb-0">{{$t('profile.edit.about-intro')}}</p>
              <v-menu
                v-model="showDobPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="formattedDob"
                    :label="$t('profile.edit.birthdate')"
                    persistent-hint
                    prepend-icon="fa-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  no-title 
                  ref="dobPicker"
                  v-bind:value="dob"
                  v-on:input="handleDobChange"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1920-01-01"
                  >
                </v-date-picker>
              </v-menu>

              <p class="mb-0">{{$t('profile.edit.gender')}}</p>
              <v-radio-group v-model="profile.gender" row class="mt-0" >
                <v-radio :label="$t('shared.not-specified')" value="_"></v-radio>
                <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
                <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
                <!-- Can be added later, when needed: <v-radio :label="$t('shared.gender-other')" value="O"></v-radio> -->
              </v-radio-group>

              <div v-if="tenant.id !== 'wmm'">
                <h3 class="subtitle">{{$t('profile.edit.fitness-level-title')}}</h3>
                <p class="mb-0">{{$t('profile.edit.fitness-level-msg')}}</p>
                <DistanceTextArea
                  v-model="profile.step_goal" 
                  class="mb-4"
                  :mode="$helpers.UnitType.NUMBER"
                  :label="$t('profile.edit.fitness-step-goal')" 
                  :hint="$t('profile.edit.fitness-step-goal-hint')" 
                  />
                <DistanceTextArea
                  v-model="profile.time_goal" 
                  class="mb-4"
                  :mode="$helpers.UnitType.NUMBER"
                  :multiplier="60"
                  :label="$t('profile.edit.fitness-time-goal')" 
                  :hint="$t('profile.edit.fitness-time-goal-hint')" 
                  />
                <DistanceTextArea
                  v-model="profile.kcal_goal" 
                  class="mb-4"
                  :mode="$helpers.UnitType.NUMBER"
                  :label="$t('profile.edit.fitness-kcal-goal')" 
                  :hint="$t('profile.edit.fitness-kcal-goal-hint')" 
                  />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Privacy -->
          <v-expansion-panel v-if="tenant.id == 'wmm'">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-shield-check</v-icon>
              {{$t('profile.edit.privacy-title')}} 
              <v-spacer/> 
              <span></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile">

              <h3 class="subtitle">{{$t('profile.edit.privacy-title')}}</h3>
              <v-switch v-model="profile.private" class="my-0" :label="$t('profile.edit.private-label')" />
                <div v-if="!profile.private && profile && profile.privacy">
                  <p class="mb-0">{{$t('profile.edit.privacy-msg')}}</p>
                  <v-row>
                    <v-col align-self="center">
                      Your Display Name<br/>
                      <span class="text-muted">Default: Public</span>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="siteData.profile_privacy_levels"
                        v-model="profile.privacy.profile" 
                        item-value="type"
                        item-text="text"
                        label="Profile"
                        persistent-hint
                        hint="Who can see your profile name."
                        />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center">
                      Your Profile Picture<br/>
                      <span class="text-muted">Default: Public</span>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="siteData.profile_privacy_levels"
                        v-model="profile.privacy.picture" 
                        item-value="type"
                        item-text="text"
                        label="Picture"
                        persistent-hint
                        hint="Who can see your profile picture."
                        />
                    </v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center">
                    Profile Badges<br/>
                    <span class="text-muted">Default: Friends</span>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="siteData.profile_privacy_levels"
                      v-model="profile.privacy.badges" 
                      item-value="type"
                      item-text="text"
                      label="Badges"
                      persistent-hint
                      hint="Who can see the events you participate in."
                      />
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col align-self="center">
                    Events You Participate In<br/>
                    <span class="text-muted">Default: Friends</span>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="siteData.profile_privacy_levels"
                      v-model="profile.privacy.events" 
                      item-value="type"
                      item-text="text"
                      label="Events"
                      persistent-hint
                      hint="Who can see the events you participate in."
                      />
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col align-self="center">
                    Your Aggregated Stats<br/>
                    <span class="text-muted">Default: Friends</span>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="siteData.profile_privacy_levels"
                      v-model="profile.privacy.stats" 
                      item-value="type"
                      item-text="text"
                      label="Aggregated Stats"
                      persistent-hint
                      hint="Who can see your aggregated stats (weekly or monthly)."
                      />
                  </v-col>
                </v-row>
              </div>              
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Your Team -->
          <v-expansion-panel v-if="tenant.hasTenantTeam">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-users</v-icon>
              Your Team
              <v-spacer/> 
              <span></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile">
              <h3 class="subtitle">Your Team</h3>
              <div v-if="groupsResponse == null">Loading...</div>
              <div v-else-if="groupsResponse.data.length === 0">
                <p>You are not yet member of a team.</p>
                <p><v-btn color="primary" :to="{ name: 'profileJoinTenantTeam'}">Join Team</v-btn></p>
              </div>
              <div v-else>
                <p>You are member of the team <strong>{{ groupsResponse.data[0].name }}</strong>.</p>

                <p><v-btn text color="primary" @click="confirmLeaveTenantTeam(groupsResponse.data[0])">Leave Team</v-btn></p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>

        <v-card-text v-if="profile">
          <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">{{$t('profile.edit.submit')}}</v-btn>
          <v-snackbar v-model="profileSavedSnackbar" top color="success" :timeout="6000">{{$t('profile.edit.saved-message')}}</v-snackbar>
        </v-card-text>
        </v-form>

    
      </div>      

    </v-card>
  </v-container>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import DistanceTextArea from "@/components/DistanceTextArea";
import AvatarUploadForm from '@/components/AvatarUploadForm.vue';
import Header from './_Header.vue';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    AvatarUploadForm,
    DistanceTextArea,
    Header,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Edit', disabled: true },
      ],
      tenant: tenant,
      siteData: siteData,
      valid: true,
      showDobPicker: false,
      profile: null,
      profileSavedSnackbar: false,
      name: "",
      email: "",
      dob: null,
      manual_connecting: false,
      emailRulesOptional: [
        v => !v ||  /.+@.+/.test(v) || "E-mail must be valid",
      ],
      emailRulesRequired: [
        v => !!v || "Please enter your e-mail",
        v => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [
        v => !!v || "Please enter your name",
      ],
      groupsResponse: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    handleDobChange(e) {
      this.dob = e;
      this.showDobPicker = false;
      //console.log('Date picker emit new dob', e, 'parsed', this.formattedDob);
    },

    detectTimeZone() {
      console.log('this.profile.time_zone', DateTime.now().zoneName);
      this.profile.time_zone = DateTime.now().zoneName;
      this.$forceUpdate();
    },

    copyProfileId() {
      this.$helpers.copyToClipboard(this.profile.id, this.$toast);
    },

    async submit() {
      if (this.$refs.form.validate()) {
        console.log('this.profile.gender',this.profile.gender)
        await profileService.put({
          name: this.name,
          email: this.email,
          unit: this.profile.unit,
          gender: this.profile.gender === '' || this.profile.gender === '_' || this.profile.gender === 0 ? null : this.profile.gender,
          dob: this.dob,
          manual: this.manual_connecting,
          time_zone: this.profile.time_zone,
          step_goal: this.profile.step_goal,
          kcal_goal: this.profile.kcal_goal,
          time_goal: this.profile.time_goal,
          private: this.profile.private,
          privacy: this.profile.privacy,
        });
        this.profileSavedSnackbar = true;
        // reload
        await this.getProfile();
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get(false, true);
        this.profile = response.data;
        if (!this.profile.hasOwnProperty('gender')) {
          this.profile.gender = "_";
        }
        this.name = this.profile.name;
        this.email = this.profile.email;
        this.dob = this.profile.dob;
        this.manual_connecting = this.profile.manual;
        this.profile.privacy = this.profile.privacy || {};

        if (tenant.hasTenantTeam) {
          this.groupsResponse = (await profileService.myGroups('TENANT_TEAM')).data;
        }
      }
    },

    async confirmLeaveTenantTeam(group) {
      if (confirm(`Are you sure you want to leave the team ${group.name}? Your current result will stay with the team, new activities will no longer count for this team though.`)) {
        this.groupsResponse = null;
        const response = (await profileService.disconnectGroup(group.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully left team.`);
        this.groupsResponse = (await profileService.myGroups('TENANT_TEAM')).data;
        if (response.status == 'OK') {
        }
      }
    },
  },
  computed: {
    formattedDob: {
      get() {
        //console.log('/// dob ', this.dob, 'iso', DateTime.fromISO(this.dob), 'format', DateTime.DATE_SHORT, 'parsed', DateTime.fromISO(this.dob).toLocaleString(DateTime.DATE_SHORT));
        return !this.dob ? null : DateTime.fromISO(this.dob).toLocaleString(DateTime.DATE_SHORT);
      },
      set(value) {
        //console.log('Date picker emit value', value, 'parsed to dob:', this.dob);
      }
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    showDobPicker (val) {
      val && setTimeout(() => (this.$refs.dobPicker.activePicker = 'YEAR'))
    },
  },
};
</script>
<style lang="scss">
</style>

